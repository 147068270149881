.sortingButtonsBox {
  display: flex;
  justify-content: space-between;
  padding-left: 30%;
  padding-right: 30%;
  margin-bottom: 50px;
}

/******************************* QuestionCard */
.questionCardHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.questionCardTitle {
  font-size: calc(1.3rem + 0.5vw);
  font-weight: 800;
  color: #0090a1;
}

.questionCardCategory {
  font-weight: 800;
  font-size: calc(0.8rem + 0.2vw);
}

.questionCardNumber {
  background-color: #0090a1;
  padding: 10px;
  color: white;
  border-radius: 10px;
  font-size: calc(0.8rem + 0.2vw);
}

/******************************* Edit Question */
.difficultyLevel {
  width: 30px;
  height: 30px;
  background-color: rgb(195, 240, 128);
  border-radius: 100px;
  margin-right: 15px;
 display: flex;
align-items: center;
justify-content: center;
font-weight: 800;
font-size: 10pt;
}


/******************************** ARCHİVE */
.filterBox{
  border:solid 1px;
  padding-top: 20px;
  margin-right: 20px;
  border-radius: 15px;
}

.filterExplanation{
  /* margin-right: 30px; */
  padding: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: -40px;
  margin-left: 10px;
  background-color: white;
}

.filterInputs{
  padding: 10px;
  display: flex;
  justify-content: flex-start;

}

.pageSizeIndicator {
  width: 30px;
  height: 30px;
  background-color: rgb(195, 240, 128);
  border-radius: 100px;
  margin-right: 15px;
 display: flex;
align-items: center;
justify-content: center;
font-weight: 800;
font-size: 10pt;
}

.filterButtons{
  display: flex;
  align-items: center;
  margin-left: 40px;
}



/******************************** FOOTER */
.mebBox{
display: flex;
align-items: center;

}

.mebLogo{
  margin-right: 12px;
  height: 100px;
}

.mebText{
  font-size: 10pt;
  line-height: 1.2rem;
}

.copyRightBox{
  display: flex;
  flex-direction: column;
  text-align: right;
  line-height: 1.2rem;

}

.link{
  font-weight: 600;
  color: #0090a1;
}

.allRightsReservedText{
  font-size: 10pt;
}

.descriptionText{
  font-size: 9pt;
}